/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '@fortawesome/fontawesome-free/css/all.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800');


body {
  height: 100%;
  margin: 0;
  overscroll-behavior-x: none;
  font-family:  "Open Sans", sans-serif;
  font-size: 14px;
}



.logo{
 // background-image: url('./assets/svg/logo.svg');
 background-color: white;
  display: block;
  width: 30px;
  height: 40px;
  margin-right: 8px;
  -webkit-mask-image: url('./assets/svg/logo.svg');
  mask-image: url('./assets/svg/logo.svg');
}



svg {
  overflow: visible;
}

// .mat-mdc-icon-button.mat-mdc-button-base{
//   font-size: 1.4em;
// }



.no-text-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.fill-remaining-space {
  /* This fills the remaining space, by using flexbox. 
     Every toolbar row uses a flexbox row layout. */
  flex: 1 1 auto;
}

.hidden {
  display: none !important;
}

// .mat-drawer-content{
//    // background: #dddddd;
  
// }



.mat-mdc-radio-button {
  padding-right: 10px;
}

.mat-form-radio-label {
  color: rgba(255,255,255,.7);
  padding-right: 10px;
}


.is-outlined.is-active {
  background-color: #00d1b2 !important;
  border-color: transparent !important;
  color: #fff !important;
}

.selectable-false{
	pointer-events: none;
  }

  .selectable-true{
	pointer-events: all;
  }


.is-fullwidth {
  width: 100%;
}




